<!--
 * @Description: 入口文件顶部bar
 * @Author: luocheng
 * @Date: 2021-11-02 10:11:06
 * @LastEditors: 朱廷果 1028509503@qq.com
 * @LastEditTime: 2023-11-22 17:23:54
-->
<template>
	<div class="top-bar" @click="onTopBar">
		<section class="logo">
			<img :src="systemLogo" alt="logo">
		</section>
		<section class="top-right-box" v-if="!isUpdatePwd">
			<!-- 选择架构 -->
			<div class="select-archi-box">
				<!-- APP下载二维码 -->
				<appQrcode v-if="needAppQrcode"></appQrcode>
				<!-- 个人信息 -->
				<section class="infos">
					<UserActions></UserActions>
				</section>
				<!-- 五方 -->
				<Cooperation v-if="needFiveParties"></Cooperation>
				<!-- 架构 -->
				<SelectArchi :reload="true" v-if="needArchiInfo"></SelectArchi>
			</div>
			<!-- 底部 -->
			<div class="right-box-bottom">
				<!-- 返回首页 -->
				<section class="fix-actions">
					<a href="javascript:;" class="btn" @click="onHome" v-if="systemConfig && systemConfig.need_second">
						<img src="@/assets/images/back-home.png" alt="首页">
					</a>
					<a href="javascript:;"
           class="btn app-center"
           :class="{
							'active': showapps
						}" 
            v-if="systemConfig && systemConfig.need_app_center" @click="onAPPCenter">
						<img src="@/assets/images/app-center.png" alt="应用中心">
					</a>
				</section>
				<!-- 模块 -->
				<!-- eslint-disable -->
				<ul class="nav-list">
					<li
						class="nav-item"
						v-for="item in topNav"
						:key="item.key"
						:class="{
							'active': currentMenu &&( +currentMenu.id === +item.id || currentMenu.menu_path === item.menu_path)&& !showapps
						}"
						@click="onTopNav(2, item, true)"
						v-if="!item.is_hide
							&& judgingArchiAPermission(item.archi_limit, item.permissions, true)
							&& judgingCustomLogic(item.custom_logics)
						"
					>
						<template v-if="item.icon">
							<i :class="['element-iconfont', item.icon]" v-if="item.icon.indexOf('el-icon') === 0"></i>
							<svg aria-hidden="true" class="iconfont-svg" v-else>
								<use :xlink:href="'#' + item.icon" />
							</svg>
						</template>
						<span class="label">{{ item.name }}</span>
					</li>
				</ul>
			</div>
		</section>
		<!-- 用户中心 -->
    <APPCenter v-show="showAppCenter" @getData="onUpdateAPPData"></APPCenter>
	</div>
</template>

<script>
import cardMixin from '@/custom-component/business/cardMixin';
import mixin from './mixin';

export default {
	name: 'TopBar',
	mixins: [cardMixin, mixin],
	
};
</script>

<style lang="less" scoped>
@height: 48px;
.top-bar {
	display: flex;
	height: @height!important;
	// min-width: 860px;
	// width: 100%;
	box-sizing: border-box;
	// padding: 0 16px;
	background: #4589ff;
	user-select: none;
	background-image: url('~@/assets/images/hearder-bg2.png');
	background-size: cover;
	.logo {
    display: flex;
    // width: 408px;
    padding: 8px 0px;
    flex-direction: column;
    align-items: flex-start;
    margin: 0 16px;
		// box-sizing: border-box;
		// display: flex;
		min-width: 230px;
		// box-sizing: border-box;
		// padding-left: 10px;
		img {
      width: 100%;
			height: 100%;
			margin: auto;
			margin-right: 20px;
			margin-left: 0;
		}
	}
	.top-right-box{
		flex: 1;
		display: flex;
    flex-direction: row-reverse;
		// overflow: hidden;
		.select-archi-box{
			height: 48px;
			width: 100%;
			display: flex;
			flex-direction: row-reverse;
			padding-right: 32px;
			box-sizing: border-box;
      :deep(.qrcode-box){
        left:-88px !important;
      }
			.select-archi{
				height: 100%;
				// float: right;
			}
		}
		.right-box-bottom{
			min-height: 48px;
			display: flex;
			.manage-center {
				box-sizing: border-box;
				padding: 12px 16px;
				display: flex;
				cursor: pointer;
				img {
					margin-top: 1px;
					height: 22px;
					width: 22px;
					margin-right: 8px;
				}
				.label {
					line-height: 24px;
					box-sizing: border-box;
					padding: 0 15px;
					color: #fff;
					font-size: 16px;
					font-family: PingFangSC, PingFangSC-Regular;
					font-weight: 400;
					text-overflow: ellipsis;
					white-space: nowrap;
					overflow: hidden;
				}
				.iconfont {
					font-size: 16px;
					line-height: 24px;
					color: #fff;
				}
			}
			.fix-actions{
				display: flex;
				height: 100%;
				box-sizing: border-box;
				padding: 0 15px;
				margin-top: 1px;
				.btn{
					box-sizing: border-box;
					padding: 15px 10px;
          &.active {
            color: #fff;
            opacity: 1;
            background: rgba(255, 255, 255, 0.2);
          }
					&:hover{
						background: linear-gradient(180deg, #1198DF 0%, #2FCAF5 100%) !important;
						border: none!important;
						color: #fff!important;
						font-weight: normal!important;
					}
					img{
						display: block;
						height: 20px;
						width: 20px;
					}
				}
			}
			.nav-list {
				flex: 1;
				display: flex;
				height: 100%;
				overflow: hidden;
				overflow-x: auto;
				.nav-item {
					// min-width: 112px;
					display: flex;
					height: 100%;
					box-sizing: border-box;
					padding: 12px 24px;
					text-align: center;
					font-size: 16px;
					font-family: var(--systemFontFamily);
					font-weight: 500;
					color: #fff;
					line-height: 52px;
					background: transparent;
					cursor: pointer;
					white-space: nowrap;
					padding: 0 20px;
					font-size: 16px;
					font-family: var(--systemFontFamily);
					display: inline-block;
					position: relative;
					&.active {
						background: linear-gradient(180deg, #1198DF 0%, #2FCAF5 100%) !important;
						border: none!important;
						color: #fff!important;
						font-weight: normal!important;
            span{
              opacity: 1;
            }
					}
					.iconfont-svg {
						height: 100%;
            width: 16px;
            height: 26px;
            position: relative;
            top: 4px;
            background-color: transparent;
            margin-right: 5px;
						font-size: 16px;
						line-height: 16px;
						padding-right: 5px;
						color: #fff;
          }
					.element-iconfont{
						height: 30px;
						line-height: 30px;
						padding-right: 5px;
					}
					span{
						height: 100%;
						font-size: 16px;
						line-height: 52px;
            opacity: 0.7;
					}
				}
			}
			.infos {
				display: flex;
				box-sizing: border-box;
				padding-left: 16px;
				.info-item {
					// height: @height;
					height: 100%;
					width: @height;
					box-sizing: border-box;
					line-height: 24px;
					font-size: 16px;
					color: #fff;
					box-sizing: border-box;
					padding: 12px 0;
					text-align: center;
					cursor: pointer;
					&:hover {
						color: #fff;
						background: rgb(77, 134, 232);
						opacity: 1;
					}
					&.active {
						color: #fff;
						opacity: 1;
						background: rgba(255, 255, 255, 0.2);
					}
				}
			}
		}
	}
}
</style>