<!--
 * @Description: 类南华顶部主题
 * @Author: luocheng
 * @Date: 2022-07-13 15:06:35
 * @LastEditors: hw 315249041@qq.com
 * @LastEditTime: 2024-04-16 18:03:14
-->
<template>
  <div class="theme-three" v-if="systemConfig" :style="bgObj">
    <section class="logo">
      <img :src="systemConfig.little_logo || systemConfig.logo" :alt="systemConfig.name">
      <h3 class="title">{{ $getLocalizedLabel({'zhLabel': systemConfig.sortName, 'enLabel': systemConfig.en_sort_name}) || systemConfig.name }}</h3>
    </section>
		<template v-if="!isUpdatePwd">
			<section class="fix-actions">
				<!-- 返回二屏 -->
				<a href="javascript:;"
             class="back-second" @click="onHome" v-if="systemConfig.need_second">
					<img src="@/assets/images/back-home.png" alt="首页">
				</a>
				<!-- 应用中心 -->
				<a href="javascript:;"
            :class="{
              'active': showapps
            }"
						v-if="systemConfig.need_app_center"
            class="app-center" @click="onAPPCenter">
					<i class="iconfont iconc-myapp"></i>
				</a>
			</section>
			<!-- 模块 -->
			<!-- eslint-disable -->
			<ul class="nav-list">
				<template v-if="!isUpdatePwd">
					<li
						class="nav-item"
						v-for="item in topNav"
						:key="item.key"
						:class="{
							'active': currentMenu && ( +currentMenu.id === +item.id || currentMenu.menu_path === item.menu_path) && !showapps
						}"
						@click="onTopNav(2, item, true)"
						v-if="!item.is_hide
							&& judgingArchiAPermission(item.archi_limit, item.permissions, true)
							&& judgingCustomLogic(item.custom_logics)
						"
					>
						<template v-if="item.icon">
							<i :class="['element-iconfont', item.icon]" v-if="item.icon.indexOf('el-icon') === 0"></i>
							<!-- <svg aria-hidden="true" class="iconfont-svg" v-else>
								<use :xlink:href="'#' + item.icon" />
							</svg> -->
							<i v-else :class="['iconfont', item.icon]"></i>
						</template>
						<span class="label">{{ $getLocalizedLabel({'zhLabel': item.name, 'enLabel': item.en_name}) }}</span>
					</li>
				</template>
			</ul>
			<!-- 附加信息 -->
			<section class="additional-content">
				<!-- 五方 -->
				<Cooperation v-if="needFiveParties"></Cooperation>
				<!-- 架构 -->
				<SelectArchi v-if="needArchiInfo" :reload="true" :archiDescBox="{
          padding: '0px 10px'
        }"></SelectArchi>
				<!-- APP下载二维码 -->
				<appQrcode v-if="needAppQrcode"></appQrcode>
				<!-- 个人信息 -->
				<UserActions
					:infoDescBoxStyle="{
						padding: '9px 10px'
					}"
					:avatarStyle="{
						height: '32px',
						width: '32px'
					}"
					:nameStyle="{
						lineHeight: '16px'
					}"
				></UserActions>
				<!-- 中英文切换 -->
				<TranslateSwitch v-if="systemConfig.enable_multi_language" status="secondary"></TranslateSwitch>
			</section>
		</template>
    <Apphome v-show="showAppCenter" @close="onCloseAppHome"></Apphome>
  </div>
</template>

<script>
import mixin from './mixin';
import cardMixin from '@/custom-component/business/cardMixin';
// import appQrcode from '@/views/newSecond/components/appQrcode/Index'
import TranslateSwitch from "@/views/newSecond/components/translateSwitch/index.vue";

export default {
  name: 'ThemeTree',
	components: {
		TranslateSwitch
	},
	mixins: [cardMixin, mixin],
	data() {
		return {
			defaultUrl: 'https://openim-1309784708.cos.ap-shanghai.myqcloud.com/226ee2d62ebcd709495c6558cdd7dd18.png'
		}
	},
  computed: {
    bgObj() {
      return {
        backgroundImage: `url(${this.systemConfig?.theme_append_info?.bgImg || this.defaultUrl})`,
      }
    }
  }
}
</script>

<style lang="less" scoped>
@height: 48px;
.theme-three{
  display: flex;
	height: @height;
	min-width: 860px;
	width: 100%;
	box-sizing: border-box;
	padding: 0 16px;
  padding-right: 6px;
	background: #4589ff;
	user-select: none;
  background-origin: 0 0;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  .logo {
		box-sizing: border-box;
		padding: 8px 0;
		display: flex;
		min-width: 32px;
    padding-right: 30px;
		img {
			display: block;
			height: 32px;
			object-fit: cover;
			border-radius: 6px;
		}
		.title {
			box-sizing: border-box;
			padding: 5px 16px;
      padding-right: 0;
			font-size: 16px;
			font-family: var(--systemFontFamily);
			font-weight: 500;
			text-align: left;
			color: #ffffff;
			line-height: 22px;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}
		span {
			display: block;
			height: 18px;
			margin-top: 7px;
			width: 1px;
			background: #fff;
		}
	}
	.fix-actions{
		display: flex;
    padding-right: 10px;
		.back-second{
			box-sizing: border-box;
      min-width: 40px;
			padding: 12px 10px;
			&:hover{
				color: #fff;
        background: rgba(255, 255, 255, 0.1);
				opacity: 1;
			}
			img{
				display: block;
				height: 20px;
				margin: 2px;
			}
		}
		.app-center {
			box-sizing: border-box;
      min-width: 40px;
      text-align: center;
			padding: 12px 10px;
			display: flex;
			cursor: pointer;
      &.active {
				color: #fff;
				opacity: 1;
				background: rgba(255, 255, 255, 0.2);
			}
			&:hover{
				color: #fff;
        background: rgba(255, 255, 255, 0.1);
				opacity: 1;
			}
			img {
				margin-top: 1px;
				height: 22px;
				width: 22px;
				margin-right: 8px;
			}
			.label {
				line-height: 24px;
				box-sizing: border-box;
				padding: 0 15px;
				color: #fff;
				font-size: 16px;
				font-family: PingFangSC, PingFangSC-Regular;
				font-weight: 400;
				text-overflow: ellipsis;
				white-space: nowrap;
				overflow: hidden;
			}
			.iconfont {
				font-size: 20px;
				line-height: 26px;
				color: #fff;
			}
		}
	}
	.nav-list {
		flex: 1;
		display: flex;
		min-height: @height;
		overflow: hidden;
		overflow-x: auto;
		.nav-item {
      display: flex;
			width: auto;
			min-height: @height;
			box-sizing: border-box;
			padding: 12px 20px;
			text-align: center;
			font-family: var(--systemFontFamily);
			font-weight: 500;
			line-height: 24px;
      color: rgba(255, 255, 255, 0.6);
			background: transparent;
			cursor: pointer;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			flex-shrink: 0;
			&:hover {
				color: #fff;
        background: rgba(255, 255, 255, 0.1);
				opacity: 1;
        .label {
          color: #fff;
          opacity: 1;
        }
			}
			&.active {
				color: #fff;
				opacity: 1;
				background: rgba(255, 255, 255, 0.2);
			}
			.element-iconfont{
        box-sizing: border-box;
        font-size: 20px;
        line-height: 26px;
				margin-right: 8px;
			}
			.iconfont {
        box-sizing: border-box;
				font-size: 20px;
        line-height: 26px;
				margin-right: 8px;
			}
			.label{
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.5px;
				height: 100%;
			}
		}
	}
	.additional-content{
		display: flex;
    align-items: center;
	}
}
.translate-switch {
	margin: 0 10px;
}
</style>