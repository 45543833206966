import { render, staticRenderFns } from "./MenuPartThree.vue?vue&type=template&id=b0f9c9d6&scoped=true"
import script from "./MenuPartThree.vue?vue&type=script&lang=js"
export * from "./MenuPartThree.vue?vue&type=script&lang=js"
import style0 from "./MenuPartThree.vue?vue&type=style&index=0&id=b0f9c9d6&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b0f9c9d6",
  null
  
)

export default component.exports